<template>
  <div class="annotation-images">
    <div class="annotation-images-row">
      <div class="container text-center">
        <div class="annotation-images-row">
          <b-row align-h="center" class="pt-3 px-3">
            <b-input-group class="rounded-pill navy-fields">
              <template #prepend>
                <b-input-group-text class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control">
                  <font-awesome-icon icon="search" />
                </b-input-group-text>
              </template>
              <template #append>
                <b-input-group-text class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control">
                </b-input-group-text>
              </template>
              <b-form-input v-model="search" placeholder="Search Image Name" style="width: 25rem"></b-form-input>
              <b-dropdown class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{
                  sg.name }}</b-dropdown-item>
              </b-dropdown>
            <!-- <b-col>
              <b-dropdown class="my-class ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                text="Pathology" variant="warn">
                  <b-checkbox class="mx-2" v-for="_p in pathList" :key="_p.label" v-model="filterChoicePathArray[_p.label]" @change="addPath(_p.label)">{{
                                _p.label }}</b-checkbox>
              </b-dropdown>
            </b-col> -->
            </b-input-group>
          </b-row>
          <!-- Loop through the images and display them -->
          <b-row class="mx-4" :key="rerender">
            <b-col v-for="image in paginatedData" :key="pageNumber + '_' + image.submission._id" cols="3" class="mb-4">
              <div class="image-container">
                <b-col @click="openBottomSheet(image)">
                  <img :src="imgPath(image) + '?thumb=yes'" alt="Uploaded Image" class="img-fluid image-set" :style="'border-color: ' + imgStyle(image) + ';'" />
                  <div>
                    <small class="image-name text-wite">{{ imgName(image) }}</small>
                  </div>
                </b-col>
                <!-- Display the indicator with red or green color based on 'trained' property  :class="imgStyle(image) ? 'trained-green' : 'trained-red'"-->
                <small class="indicator" :style="'background-color: ' + imgStyle(image)">
                  {{ imgStyle(image) === 'purple' ?
                    "Not yet analysed" :
                      imgStyle(image) === 'green' ?
                        "Full agreement" :
                          imgStyle(image) === 'red' ?
                            "Full disagreement" :
                              imgStyle(image) === 'orange' && imgAnalysed(image) ?
                                "Partial agreement" : "Not yet reported" }}
                </small>
                <small class="indicator-set trained-green" v-if="false">
                  {{ image.details.image_set.sequence }}
                </small>
              </div>
            </b-col>
          </b-row>

          <div class="text-center" v-if="paginatedData.length === 0">
            <!-- Show icon and message when images are empty -->
            <div class="empty-images mb-5">
              <i class="fa fa-image fa-3x"></i>
              <p>No images Annotated.</p>
            </div>
          </div>
          <b-row align-h="center">
        <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage" :disabled="this.pageNumber===0">
          <i class="fa fa-chevron-left"></i>
        </b-button>
        <b-button v-if="pageNumber -3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)" :disabled="this.pageNumber===0">
          1
        </b-button>
        <p class="ml-3  mt-2"  v-if="pageNumber -3 > 0">...</p>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -2)" v-if="pageNumber-2 >= 0">
          {{ pageNumber -1  }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -1)" v-if="pageNumber-1 >= 0">
          {{ pageNumber }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
          {{ pageNumber +1 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +1)" v-if="pageNumber +1 < maxPages">
          {{ pageNumber +2 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +2)" v-if="pageNumber +2 < maxPages">
          {{ pageNumber +3 }}
        </b-button>
        <p class="ml-3 mt-2" v-if="pageNumber +3 < maxPages">...</p>
        <b-button v-if="pageNumber +3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(maxPages -1)">
          {{maxPages}}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage" :disabled="this.pageNumber  +1  ===maxPages">
          <i class="fa fa-chevron-right"></i>
        </b-button>
        <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
          <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
        </b-dropdown>
      </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IOVThesholds } from '../../../../helpers/iovthresholds'
const { apiRoute } = require('./../../../../helpers/')

export default {
  name: 'AnnotationImages',
  props: {
    images: Array,
    project: Object
  },
  data () {
    return {
      selectedImage: null,
      filterChoice: '',
      filterChoicePath: '',
      filterChoiceDia: '',
      search: '',
      pageNumber: 0,
      itemsPerPage: 16,
      filterChoicePathArray: {},
      filterChoicePathArray2: [],
      rerender: 0
    }
  },
  methods: {
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    openBottomSheet (image) {
      // // // console.log('abc', image)
      if (this.selectedImage === image) {
        // Deselect the image if it's already selected
        this.selectedImage = null
      } else {
        // Select the image
        this.selectedImage = image
      }
      this.$emit('open-bottom-sheet', image)
    },
    hasPath (img, filter) {
      // // console.log(Object.keys(filter).length)
      var temp = false
      if (this.filterChoicePathArray2.length === 0) {
        temp = true
      }
      Object.keys(filter).forEach(_ch => {
        if (_ch === 'Other' && this.filterChoicePathArray[_ch]) {
          img.details.pathologies.forEach(v => {
            if (!this.project.pathologies.find(p => p.label === v)) {
              temp = true
            }
          })
          // // // console.log(img.details.pathologies.values())
          // // // console.log(this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1) && this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1
          // && this.project.pathologies.filter(p => img.details.pathologies.has(p))) {p => !this.project.pathologies.includes(p)
        } else if (_ch !== 'Other' && this.filterChoicePathArray[_ch] && img.details.pathologies.has(_ch)) {
          temp = true
        }
      })
      // // console.log(temp)
      return temp
    },
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.details.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        }
        if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            if (img.details.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.details.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.details.meta && img.details.meta[0] && img.details.meta[0] && img.details.meta[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.details.meta[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    },
    imgName (img) {
      if (img.submission) {
        if (img.submission && img.submission.patient) {
          return img.submission.patient.identifier
        } else if (img.submission && img.submission.study_id) {
          return img.submission.study_id
        } else if (img.submission && img.submission.attachments && img.submission.attachments.length > 0) {
          return img.submission.attachments[0].name
        } else {
          return false
        }
      } else {
        return 'Unknown'
      }
    },
    imgPath (img) {
      if (img.submission && img.submission.attachments && img.submission.attachments.length > 0) {
        if (img.submission.attachments[0].path.indexOf('http') < 0) {
          return apiRoute() + img.submission.attachments[0].path
        } else {
          return img.submission.attachments[0].path
        }
      } else {
        return ''
      }
    },
    imgStyle (img) {
      if (img.reportAnalysis) {
        if (img.reportAnalysis.status === 'Completed') {
          if ((1 - img.reportAnalysis.accuracy) <= IOVThesholds().fullAgreement) {
            return 'green'
          } else if ((1 - img.reportAnalysis.accuracy) <= IOVThesholds().partialAgreement) {
            return 'orange'
          } else {
            return 'red'
          }
        } else {
          return 'purple'
        }
      } else {
        return 'orange'
      }
    },
    imgAnalysed (img) {
      if (img.reportAnalysis) {
        return img.reportAnalysis.status === 'Completed'
      } else {
        return false
      }
    },
    inSearch  (img, val) {
      return this.imgName(img).includes(val)
    },
    addPath (val) {
      if (this.filterChoicePathArray2.includes(val)) {
        this.filterChoicePathArray2 = this.filterChoicePathArray2.filter(p => p !== val)
      } else {
        this.filterChoicePathArray2.push(val)
      }
    },
    compareLabel (a, b) {
      return a.label.localeCompare(b.label)
    }
  },
  computed: {
    pathList () {
      var paths = this.project.pathologies
      var _paths = paths.sort(this.compareLabel)
      return _paths
    },
    reducedImages () {
      this.filterChoicePathArray2.forEach(_key => {
        // // console.log('key', _key)
      })
      var _images = this.images.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
      // _images = _images.filter(img => this.hasPath(img, this.filterChoicePathArray))
      if (this.filterChoice !== 'All' && this.filterChoice !== '') {
        _images = _images.filter(img => this.hasGroup(img, this.filterChoice))
      }
      if (this.search !== '') {
        _images = _images.filter(img => this.inSearch(img, this.search))
      }
      return _images
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.reducedImages.slice(start, end)
    },
    maxPages () {
      const _pages = Math.ceil(this.reducedImages.length / this.itemsPerPage)
      return _pages <= 0 ? 1 : _pages
    },
    filterChange () {
      return this.filterChoicePathArray2
    }
  },
  watch: {
    filterChange (_new) {
      // // console.log('change', _new)
    }
  },
  mounted () {
    if (this.project) {
      this.project.pathologies.forEach(_p => {
        this.filterChoicePathArray[_p.label] = false
      })
    }
    // // // console.log(Object.values(this.filterChoicePathArray))
  }
}
</script>

<style scoped>
.annotation-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-fluid {
  border-radius: 10px;
  border: 0.5px solid white;
}
.empty-images {
  color: #ccc;
  margin-top: 50px;
}
</style>

<style scoped>
  .my-class /deep/ .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
.indicator-set {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 35px;
  left: 5px;
}
</style>
