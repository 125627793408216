<template>
  <div class="bottom-sheet pb-4">
    <div class="bottom-sheet-content">
      <DataAnnotations :fullscreenprop="false" v-on:close-bottom="closeBottom" />
    </div>
  </div>
</template>

<script>
import DataAnnotations from './DataAnnotations.vue' // Adjust the import path

export default {
  name: 'BottomSheet',
  components: {
    DataAnnotations
  },
  props: {
  },
  methods: {
    closeBottom () {
      this.$emit('closeBottom')
    }
  }
}
</script>
